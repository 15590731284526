import React from "react"
import SEO from "../modules/seo"
import "./common.css"
import Contact from "../components/contact"
import { ScrollButton } from "../modules/buttons"
import { Footer } from "../modules/footer"
import { Whatis } from "../components/whatis"
import { Showcases } from "../components/showcases"
import { Vision } from "../components/vision"
import { DigitalShift } from "../components/digitalshift"
import { Location } from "../components/location"
import { Systems } from "../components/systems"
import { Recruit } from "../components/recruit"
import { Helmet } from "react-helmet"

class LinkTags extends React.Component {
  render() {
    return (
      <Helmet>
        <title>オプトテクノロジーズ - Opt Technologies | 株式会社オプト</title>
        <link
          href="https://fonts.googleapis.com/css?family=Noto+Sans+JP"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Raleway&amp;display=swap"
          rel="stylesheet"
        />
      </Helmet>
    )
  }
}

const IndexPage = () => (
  <>
    <SEO />
    <LinkTags />
    <Whatis />
    <DigitalShift />
    <Vision />
    <Showcases />
    <Location />
    <Systems />
    <Recruit />
    <Contact />
    <ScrollButton scrollStepInPx="50" delayInMs="0.8">
      TOP
    </ScrollButton>
    <Footer>© {new Date().getFullYear()}, Opt Technologies</Footer>
    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </>
)

export default IndexPage
