import styled from "styled-components"

export const AnimatedWrap = styled.div`
  & .wrap {
    background-size: 900vw;
  }
  & .is-inview {
    .wrap {
      background-size: 100vw;
      transition: all 0.4s 0.8s;
    }
  }
`
