import React from "react"
import { Link } from "react-scroll"
import styled from "styled-components"
import colors from "../utility/colors"

export class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }
  toggleMenu() {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    return (
      <Container open={this.state.open}>
        <Toggle onClick={() => this.toggleMenu()} open={this.state.open}>
          <i></i>
          <i></i>
          <i></i>
        </Toggle>
        <Drawer open={this.state.open}>
          <li>
            <Link
              to="top"
              onClick={() => this.toggleMenu()}
              smooth={true}
              offset={0}
            >
              TOP
            </Link>
          </li>
          <li>
            <Link
              to="mission"
              onClick={() => this.toggleMenu()}
              smooth={true}
              offset={0}
            >
              MISSION
            </Link>
          </li>
          <li>
            <Link
              to="vision"
              onClick={() => this.toggleMenu()}
              smooth={true}
              offset={-30}
            >
              VISION
            </Link>
          </li>
          <li>
            <Link
              to="products"
              onClick={() => this.toggleMenu()}
              smooth={true}
              offset={-30}
            >
              PRODUCTS
            </Link>
          </li>
          <li>
            <Link
              to="technologies"
              onClick={() => this.toggleMenu()}
              smooth={true}
              offset={-30}
            >
              TECHNOLOGIES
            </Link>
          </li>
          <li>
            <Link
              to="location"
              onClick={() => this.toggleMenu()}
              smooth={true}
              offset={-30}
            >
              LOCATION
            </Link>
          </li>
          <li>
            <Link
              to="workstyle"
              onClick={() => this.toggleMenu()}
              smooth={true}
              offset={-30}
            >
              WORK STYLE
            </Link>
          </li>
          <li>
            <Link
              to="magazine"
              onClick={() => this.toggleMenu()}
              smooth={true}
              offset={-30}
            >
              MAGAZINE
            </Link>
          </li>
          <li>
            <Link
              to="recruit"
              onClick={() => this.toggleMenu()}
              smooth={true}
              offset={-30}
            >
              RECRUIT
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              onClick={() => this.toggleMenu()}
              smooth={true}
              offset={-30}
            >
              CONTACT
            </Link>
          </li>
        </Drawer>
      </Container>
    )
  }
}

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 50px;
  height: 100vh;
  z-index: 5;

  &[open] {
    overflow: visible;
    background: #fff;
    width: 100vw;
  }
`

const Toggle = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  z-index: 10;
  cursor: pointer;
  margin: auto;
  background: #fff;

  > i {
    position: absolute;
    display: block;
    height: 2px;
    background: ${props => colors.primary};
    width: 15px;
    left: 18px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:nth-child(1) {
      top: 19px;
    }
    &:nth-child(2) {
      top: 24px;
    }
    &:nth-child(3) {
      top: 29px;
    }
  }

  &[open] {
    right: 0px;
    transition: right linear 0.2s;
    margin-right: 0;

    > i {
      &:nth-child(1) {
        top: 25px;
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
      }
      &:nth-child(2) {
        background: transparent;
      }
      &:nth-child(3) {
        top: 25px;
        -webkit-transform: rotateZ(-45deg);
        transform: rotateZ(-45deg);
      }
    }
  }
`

const Drawer = styled.ul`
  width: 400px;
  height: 94vh;
  background-color: #fff;
  position: absolute;
  z-index: 2;
  right: 0;
  top: -150vh;
  transition: top linear 0.2s;
  padding: 16px 0;

  &[open] {
    top: 50px;
    transition: top linear 0.2s;
    width: 100vw;
  }

  li {
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 24px;
    text-align: center;
    color: ${props => colors.primary};
    &:hover {
      color: #0094fc;
    }
    cursor: pointer;
  }
`
