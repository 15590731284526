import React from "react"
import styled from "styled-components"
import { Headline, HeadlineJp } from "../modules/headlines"
import colors from "../utility/colors"
import customMedia from "../utility/media"
import {
  frontendItems,
  dataScienceItems,
  infraDevOpsItems,
  backendItems,
} from "./technologies-data"

export const Technologies = () => (
  <TechnologiesWrap>
    <Headline id="technologies" color="#fff" fontSize="11vw">
      TECHNOLOGIES
    </Headline>
    <HeadlineJp color="#fff">使っている技術</HeadlineJp>
    <CardWrap>
      <TechCard>
        <div className="card-contents">
          <h3 className="title">Frontend</h3>
          <ul className="list">
            {frontendItems.map((d, idx) => (
              <li className="list-item" key={idx}>
                {d}
              </li>
            ))}
          </ul>
        </div>
      </TechCard>
      <TechCard>
        <div className="card-contents">
          <h3 className="title">Backend</h3>
          <ul className="list">
            {Object.entries(backendItems).map(([key, values], i) => {
              return (
                <React.Fragment key={`${key}_${i}`}>
                  <li className="list-item">{key}</li>
                  <li>
                    <ul className="nested-list">
                      {values.map((v, i) => (
                        <li className="nested-item" key={i}>
                          {v}
                        </li>
                      ))}
                    </ul>
                  </li>
                </React.Fragment>
              )
            })}
          </ul>
        </div>
      </TechCard>
      <TechCard>
        <div className="card-contents">
          <h3 className="title">Infra/DevOps</h3>
          <ul className="list">
            {Object.entries(infraDevOpsItems).map(([key, values], i) => {
              return (
                <React.Fragment key={`${key}_${i}`}>
                  <li className="list-item">{key}</li>
                  <li>
                    <ul className="nested-list">
                      {values.map((v, i) => (
                        <li className="nested-item" key={i}>
                          {v}
                        </li>
                      ))}
                    </ul>
                  </li>
                </React.Fragment>
              )
            })}
          </ul>
        </div>
      </TechCard>
      <TechCard>
        <div className="card-contents">
          <h3 className="title">Data Science</h3>
          <ul className="list">
            {dataScienceItems.map((d, idx) => {
              return (
                <li className="list-item" key={idx}>
                  {d}
                </li>
              )
            })}
          </ul>
        </div>
      </TechCard>
    </CardWrap>
  </TechnologiesWrap>
)

const CardWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 28px 16px;
  overflow: hidden;
  ${customMedia.greaterThan("main")`
    grid-template-columns: repeat(4, 1fr);
    margin: 32px auto 0;
  `}
  ${customMedia.greaterThan("large")`
    max-width: 1200px;
    overflow: visible;
  `}
`

const TechCard = styled.div`
  transform: skew(-14deg);
  background: #fff;
  padding-bottom: 16px;

  & .card-contents {
    transform: skew(14deg);
    margin: 24px auto 16px 75px;
    height: 100%;
  }

  & .title {
    font-size: 1.2rem;
    font-weight: bold;
    width: 90%;
    margin: 0 auto;
  }
  & .list {
    width: 100%;
    margin: 0 auto;
    font-size: 0.75rem;
    padding-left: 8px;
  }
  &:before {
    content: "";
    display: block;
    width: 32px;
    height: 8px;
    background: ${props => colors.attention};
    margin-left: 16px;
  }

  & .list-item {
    display: flex;
    align-items: center;
    &:before {
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 50%;
      display: block;
      background: #000;
      margin-right: 4px;
      margin-top: 2px;
    }
  }

  & .nested-list {
    padding-left: 8px;
  }

  & .nested-item {
    display: flex;
    align-items: center;
    &:before {
      content: "-";
      width: 4px;
      /* height: 4px; */
      /* border-radius: 50%;
      display: block;
      background: #fff;
      border: 1px solid ${props => colors.primary}; */
      margin-right: 4px;
      /* margin-top: 2px; */
    }
  }
`

const TechnologiesWrap = styled.div`
  padding: 48px 0;
`
