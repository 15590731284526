import styled from "styled-components"
import colors from "../utility/colors"
import customMedia from "../utility/media"

export const Headline = styled.h2`
  width: 100%;
  text-align: center;
  font-style: italic;
  font-size: 36px;
  ${customMedia.greaterThan("cozy")`
    font-size: 60px;
  `}
  font-family: Helvetica, sans-serif;
  margin-bottom: 0;
  color: ${props => props.color};
  &::before {
    content: "";
    width: 20px;
    height: 18px;
    background: ${props => colors.attention};
    display: block;
    margin: 0 auto;
    transform: skewX(-45deg);
  }
`

Headline.defaultProps = {
  color: "#000",
}

export const HeadlineJp = styled.div`
  color: ${props => props.color};
  text-align: center;
`

HeadlineJp.defaultProps = {
  color: "#000",
}
