import React from "react"
import styled from "styled-components"
import { Headline, HeadlineJp } from "../modules/headlines"
import colors from "../utility/colors"
import customMedia from "../utility/media"
import InviewMonitor from "react-inview-monitor"

export const Magazine = () => {
  const [hatenaFeedsState, setHatenaFeedsState] = React.useState([])
  React.useEffect(() => {
    fetch("https://tech-magazine.opt.ne.jp/feed")
      .then(res => res.text())
      .then(texts => {
        const hatenaResponseXml = new DOMParser().parseFromString(
          texts,
          "text/xml"
        )
        const hatenaFeeds = Array.from(
          hatenaResponseXml.querySelectorAll("entry") || []
        ).map(entry => {
          const [entryLinkDom, imageLinkDom] =
            entry.querySelectorAll("link") || []
          const entryUrl = entryLinkDom.attributes.href.value
          const imageUrl = imageLinkDom.attributes.href.value

          const titleDom = entry.querySelector("title")
          const title = titleDom.textContent

          // NOTE: デザイン上これ使ってないけど一応homepageリポジトリから移植はしといた。いらないなら消してください
          const substring100 = text =>
            text.length > 99 ? text.substring(0, 99) + "…" : text
          const summaryDom = entry.querySelector("summary")
          const summary = substring100(summaryDom.textContent)

          const style = {
            backgroundImage: `url(${imageLinkDom.attributes.href.value})`,
          }

          const categories = entry.querySelectorAll("category")
          const shouldShowMagazine = !Array.from(categories).find(
            c => c.attributes.term.value === "エンジニアの1日"
          )
          return {
            entryUrl,
            imageUrl,
            title,
            summary,
            style,
            shouldShowMagazine,
          }
        })
        setHatenaFeedsState(hatenaFeeds)
      })
  }, [])

  return (
    <MagazineWrap>
      <Headline id="magazine" color="#fff">
        MAGAZINE
      </Headline>
      <HeadlineJp color="#fff">Opt Technologies Magazine</HeadlineJp>

      <BlogCardWrap>
        {hatenaFeedsState
          .filter(({ shouldShowMagazine }) => shouldShowMagazine)
          .filter((_, i) => i < 3)
          .map(({ entryUrl, imageUrl, title, style }, i) => {
            return (
              <InviewMonitor
                key={`hatena_feed_${i}`}
                classNameInView="is-inview"
              >
                <BlogCard
                  className="blog-card"
                  href={entryUrl}
                  target="_blank"
                  rel="noopener"
                >
                  <div className="blog-image-wrap" style={style} alt={title}>
                    {/* <img className="blog-image" src={imageUrl} /> */}
                  </div>
                  <div className="blog-title">{title}</div>
                </BlogCard>
              </InviewMonitor>
            )
          })}
      </BlogCardWrap>
    </MagazineWrap>
  )
}

const MagazineWrap = styled.div`
  padding: 48px 0;
`

const BlogCardWrap = styled.div`
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;

  ${customMedia.greaterThan("main")`
    display: grid;
    grid-template-columns: repeat(3, 30%);
    justify-content: space-between;
  `}

  & .blog-card {
    opacity: 0;
  }

  & .is-inview {
    & .blog-card {
      opacity: 1;
      transition: opacity 0.2s 0.2s;
    }
  }
`

const BlogCard = styled.a`
  width: 100%;
  margin: 32px auto;
  display: block;
  background: #fff;
  &:hover {
    text-decoration: none;
  }

  & .blog-image-wrap {
    height: 240px;
    overflow-y: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  & .blog-title {
    border-left: 4px solid ${props => colors.attention};
    font-weight: bold;
    min-height: 64px;
    color: #000;
    text-decoration: none;
    padding: 8px 16px 8px 16px;
  }
`
