import React from "react"
import { Headline, HeadlineJp } from "../modules/headlines"
import styled from "styled-components"
import {
  datamarketing,
  operation,
  ai,
  managementstrategy,
} from "./product-data"
import customMedia from "../utility/media"
import InviewMonitor from "react-inview-monitor"
import colors from "../utility/colors"
import { Button } from "../modules/buttons"

const ProductItem = ({ prod }) => {
  const [showModal, setShowModal] = React.useState(false)
  return (
    <>
      <ProductCardWrap onClick={() => setShowModal(true)}>
        <ImageWrap>
          <img src={prod.image} alt={prod.name} />
        </ImageWrap>
        <InviewMonitor classNameInView="is-inview">
          <Desc className="desc">{prod.desc}</Desc>
        </InviewMonitor>
      </ProductCardWrap>
      <ProductsModalWrap className={showModal ? "" : "modal"}>
        <div className="hoge">
          <ProductsModal>
            <div className="products-header">
              <img
                className="products-image"
                src={prod.image}
                alt={prod.name}
              />
              <div className="products-name">{prod.name}</div>
              <div className="products-desc">{prod.desc}</div>
            </div>
            <div className="products-detail">{prod.detail}</div>
            <div className="products-block">
              <div className="title">技術紹介</div>
              <div>{prod.tech}</div>
            </div>
            <div className="products-block">
              <div className="title">利用技術</div>
              <div>{prod.tools}</div>
              <div>{prod.tools2}</div>
            </div>
            {prod.link ? (
              <Button
                href={prod.link}
                className="products-link"
                target="_blank"
                rel="noopener"
              >
                {prod.name} 紹介ページ
              </Button>
            ) : (
              ""
            )}

            <div className="close" onClick={() => setShowModal(false)}>
              <i></i>
              <i></i>
            </div>
          </ProductsModal>
        </div>
        <div className="close-modal" onClick={() => setShowModal(false)}></div>
      </ProductsModalWrap>
    </>
  )
}
const ProductsList = ({ products }) => {
  return products.map((prod, i) => (
    <ProductItem
      prod={prod}
      key={`${prod.name.toLowerCase()}_${i}`}
    ></ProductItem>
  ))
}

export class Products extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: false,
    }
  }
  toggleModal() {
    this.setState({
      mode: !this.state.mode,
    })
  }

  render() {
    return (
      <ProductsWrap>
        <Headline id="products" color="#fff">
          PRODUCTS
        </Headline>
        <HeadlineJp color="#fff">プロダクト紹介</HeadlineJp>
        <LabelTitle id="products-data">データマーケティング</LabelTitle>
        <ProductsListWrap>
          <ProductsList products={datamarketing} />
        </ProductsListWrap>
        <LabelTitle id="products-ope">広告運用オペレーション</LabelTitle>
        <ProductsListWrap>
          <ProductsList products={operation} />
        </ProductsListWrap>
        <LabelTitle id="products-ai">AI</LabelTitle>
        <ProductsListWrap>
          <ProductsList products={ai} />
        </ProductsListWrap>
        <LabelTitle id="products-cre">経営戦略</LabelTitle>
        <ProductsListWrap>
          <ProductsList products={managementstrategy} />
        </ProductsListWrap>
      </ProductsWrap>
    )
  }
}

const ImageWrap = styled.div`
  width: 25vw;
  height: 25vw;
  max-width: 192px;
  max-height: 192px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
  margin: 0 auto;

  & img {
    display: block;
    height: 100%;
  }

  ${customMedia.greaterThan("main")`
    width: 128px;
    height: 128px;
  `}
`

const Desc = styled.div`
  width: 28vw;
  max-width: 176px;
  text-align: center;
  color: #fff;
  font-size: 0.7rem;
  margin: 8px auto;
  text-align: justify;

  ${customMedia.greaterThan("main")`
    font-size: .8rem;
    width: 128px;
  `}
`

const ProductCardWrap = styled.div`
  padding: 4px;
  cursor: pointer;
  width: 33.3%;

  & .desc {
    opacity: 0;
  }

  & .is-inview {
    & .desc {
      opacity: 100;
      transition: opacity 0.2s 0.2s;
    }
  }

  ${customMedia.greaterThan("main")`
    width: 25%;
  `}
`

const ProductsListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 960px;
  margin: auto;

  & .modal {
    display: none;
  }
`

const LabelTitle = styled.h3`
  color: #fff;
  text-align: center;
  margin-top: 48px;
  margin-bottom: 16px;
  font-size: 1.4rem;
`

const ProductsWrap = styled.div`
  padding: 48px 0;
`

const ProductsModalWrap = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 5vh;
  z-index: 10;

  .layout-modal {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
  }

  .close-modal {
    width: 100vw;
    height: 100vh;
    background: transparent;
    position: fixed;
    top: 0;
  }
`

const ProductsModal = styled.div`
  padding: 20px;
  max-width: 900px;
  max-height: 80vh;
  border: 5px solid #fff;
  color: #fff;
  margin: 0 auto;
  background: ${props => colors.primary};
  font-size: 14px;
  line-height: 1.5;
  position: relative;
  z-index: 11;
  overflow: scroll;

  & .title {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 8px;
  }

  & .products-header {
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-template-rows: auto 1fr;
    grid-row-gap: 16px;
    margin-bottom: 16px;
  }

  & .products-image {
    width: 108px;
    height: 108px;
    border-radius: 50%;
    grid-row: span 2;
  }

  & .products-name {
    margin-top: 8px;
    font-size: 20px;
  }

  & .products-desc {
    font-weight: bold;
    font-size: 16px;
  }

  ${customMedia.greaterThan("medium")`
    & .products-header {
      grid-template-columns: 200px 1fr;
      grid-template-rows: 64px 1fr;
      margin-bottom: 16px;
    }

    & .products-image {
      width: 160px;
      height: 160px;
    }

    & .products-name {
      margin-top: 8px;
      font-size: 36px;
    }

    & .products-desc {
    font-size: 18px;
    }
  `}

  & .products-block {
    margin: 24px auto;
  }

  & .close {
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 4px;
    width: 28px;
    height: 28px;
    display: block;
    cursor: pointer;

    > i {
      display: block;
      height: 2px;
      background: #fff;
      width: 24px;
      position: absolute;
      right: 0;
      top: 8px;

      &:nth-child(1) {
        transform: rotateZ(45deg);
      }
      &:nth-child(2) {
        transform: rotateZ(-45deg);
      }
    }
  }

  .products-link {
    text-decoration: none;
    color: #000;
    text-align: center;
    font-weight: bold;
  }
`
