import React from "react"
import { IconTimes, IconAlumni, IconPc } from "../images/Icons"
import IconBook from "../images/workstyle/book.svg"
import IconLicence from "../images/workstyle/licence.svg"
import IconChallenge from "../images/workstyle/challenge.svg"
import IconTelework from "../images/workstyle/telework.svg"
import IconWorkation from "../images/workstyle/workation.svg"
import ImageAihara from "../images/photos/ConsultantAihara.jpg"

export const systems = [
  {
    name: "裁量労働制",
    image: <IconTimes fill="#fff" />,
    contents:
      "中途入社のエンジニアは全員コアタイムなしの専門職型裁量労働制です。それぞれ自分のスタイルで成果や効率を最大化することを目指しています。新卒採用のエンジニアは研修修了後に基準を満たした場合に裁量労働制となります。",
  },
  {
    name: "書籍購入制度",
    image: <img src={IconBook} alt="Book" />,
    contents:
      "購入したい書籍を申請することで、会社経費で技術書等の購入が可能です。また、グループ経営陣などの推薦図書を貸し出しする「推薦図書館」などもあります。",
  },
  {
    name: "資格取得・研修補助制度",
    image: <img src={IconLicence} alt="Licence" />,
    contents: "資格取得や研修受講時にかかる費用を補助します。",
  },
  {
    name: "アルムナイ制度",
    image: <IconAlumni fill="#fff" />,
    contents:
      "Opt Technologies 卒業生のための制度です。卒業生・在職者間の交流、卒業生がオプトに出戻りしたくなったときのサポート、両者にとって未来やビジネスにつなげる、といったことを支援することが目的です。やることは食事会や飲み会です。",
  },
]

export const env = [
  {
    name: "どこでもワーク",
    image: <img src={IconTelework} alt="Telework" />,
    contents:
      "事業所の所在地（関東、沖縄本島、宮城県）以外を就業拠点として定めることが可能になります。一定の就業期間や条件をクリアした上での許可制となりますが、地方出身者、介護や育児といった事情を抱える人など、自身が望む場所で働くことができます。",
  },
  {
    name: "ワーケーション",
    image: <img src={IconWorkation} alt="Workation" />,
    contents:
      "観光地やリゾート地でリモートワークを活用しながら、働きながら休暇をとる過ごし方＝ワーケーションを推進。 グループ社員は、那須のワーケーション施設を無料で使用することが可能です。",
  },
  {
    name: "支給PCなど",
    image: <IconPc fill="#fff" />,
    contents:
      "貸与するPCはOS（macOS, Windows）やサイズの選択が可能です。東京オフィスには4Kモニタやアーロンチェアなどハイクラスな椅子も用意しています。",
  },
]

export const consultants = [
  {
    name: "粟飯原 俊介",
    image: `${ImageAihara}`,
    contents:
      "博士課程在学中にベンチャー企業のCTOとして音声処理システムの研究開発に従事。博士課程退学後にコンサルティング企業にてデータ分析部門でのコンサルタントやニュースキュレーションサービス企業の広告システム全体の開発運用責任者を担当。現在は複数の企業で、サーバサイドアプリケーション開発やデータ処理・機械学習・統計解析システム開発分野における、実装及び関連業務のサポートを行っている。",
    domain: "データ処理関連の技術レビューおよびアドバイス。新規事業開発推進",
  },
]
