import React from "react"
import styled from "styled-components"
import { Headline, HeadlineJp } from "../modules/headlines"
import colors from "../utility/colors"
import { SystemsCard, EnvCard, ConsultantsCard } from "./workstyle-cards"

export class WorkStyle extends React.Component {
  render() {
    return (
      <WorkStyleWrap>
        <Headline id="workstyle" color="#fff">
          WORK STYLE
        </Headline>
        <HeadlineJp color="#fff">制度や環境</HeadlineJp>
        <Buttons>
          <Accordions />
        </Buttons>
      </WorkStyleWrap>
    )
  }
}

const AccordionItem = ({ items }) => {
  const [expanded, setExpanded] = React.useState(false)
  return (
    <ExButtonStyle open={expanded} onClick={() => setExpanded(!expanded)}>
      <span className="title">{items.title}</span>
      <span className="detail">{expanded ? "CLOSE" : "SEE DETAIL"}</span>
      <span className="button">
        <i></i>
        <i open={expanded}></i>
      </span>
      <div className="explanation" open={expanded}>
        {items.component}
      </div>
    </ExButtonStyle>
  )
}

const Accordions = () => {
  const list = [
    {
      title: "制度",
      component: <SystemsCard />,
    },
    {
      title: "環境",
      component: <EnvCard />,
    },
    {
      title: "技術顧問",
      component: <ConsultantsCard />,
    },
  ]

  return list.map((list, i) => (
    <AccordionItem items={list} key={`${list.title.toLowerCase()}_${i}`} />
  ))
}

const ExButtonStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 104px 40px;
  cursor: pointer;
  border-left: 4px solid ${props => colors.attention};
  width: 80%;
  max-width: 800px;
  margin: 0 auto 8px;
  background: white;
  padding: 16px 36px;
  overflow: hidden;
  line-height: 28px;

  & .title {
    font-weight: bold;
  }

  & .detail {
    font-size: 0.8rem;
    text-align: right;
    margin-right: 16px;
  }

  & .button {
    width: 32px;
    height: 32px;
    background: ${props => colors.attention};
    display: block;
    position: relative;

    & i {
      position: absolute;
      display: block;
      height: 2px;
      background: #fff;
      width: 16px;
      left: 8px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;

      &:nth-child(1) {
        top: 16px;
      }
      &:nth-child(2) {
        top: 16px;
        transform: rotate(-270deg);
        &[open] {
          transform: rotate(0);
        }
      }
    }
  }

  & .explanation {
    height: 0;
    opacity: 0;
    grid-column: span 3;
    &[open] {
      opacity: 1;
      display: block;
      height: auto;
      transition: opacity 0.3s;
    }
  }
`

const WorkStyleWrap = styled.div`
  padding: 48px 0;
`

const Buttons = styled.div`
  margin-top: 32px;
`
