import { generateMedia } from "styled-media-query"

const customMedia = generateMedia({
  main: "960px",
  medium: "640px",
  cozy: "512px",
  small: "374px",
  semilarge: "1280px",
  large: "1440px",
})

export default customMedia
