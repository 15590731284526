import React from "react"
import styled from "styled-components"
import customMedia from "../utility/media"
import { Button } from "../modules/buttons"

export const OneDays = () => {
  return (
    <>
      <Heading>エンジニアの1日</Heading>
      <OneDaysWrap>
        <ArticleList />
        <Article
          entryUrl="https://tech-magazine.opt.ne.jp/archive/category/%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2%E3%81%AE1%E6%97%A5"
          imageUrl="https://cdn-ak.f.st-hatena.com/images/fotolife/o/opttechnologies2015/20191023/20191023134314.png"
          title="過去のエンジニアの1日記事一覧"
          summary="これまでのエンジニアの1日記事はこちらからご覧いただけます。"
        ></Article>
      </OneDaysWrap>
    </>
  )
}

const ArticleList = () => {
  const [hatenaFeedsState, setHatenaFeedsState] = React.useState([])
  React.useEffect(() => {
    fetch(
      "https://tech-magazine.opt.ne.jp/feed/category/%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2%E3%81%AE1%E6%97%A5"
    )
      .then(res => res.text())
      .then(texts => {
        const hatenaResponseXml = new DOMParser().parseFromString(
          texts,
          "text/xml"
        )
        const hatenaFeeds = Array.from(
          hatenaResponseXml.querySelectorAll("entry") || []
        ).map(entry => {
          const [entryLinkDom, imageLinkDom] =
            entry.querySelectorAll("link") || []
          const entryUrl = entryLinkDom.attributes.href.value
          const imageUrl = imageLinkDom.attributes.href.value

          const titleDom = entry.querySelector("title")
          const title = titleDom.textContent

          const substring100 = text =>
            text.length > 99 ? text.substring(0, 99) + "…" : text
          const summaryDom = entry.querySelector("summary")
          const summary = substring100(summaryDom.textContent)

          const style = {
            backgroundImage: `url(${imageLinkDom.attributes.href.value})`,
          }

          const categories = entry.querySelectorAll("category")
          const isEngineerOneDays = Array.from(categories).find(
            c => c.attributes.term.value === "エンジニアの1日"
          )
          return {
            entryUrl,
            imageUrl,
            title,
            summary,
            style,
            isEngineerOneDays,
          }
        })
        setHatenaFeedsState(hatenaFeeds)
      })
  }, [])

  return (
    <>
      {hatenaFeedsState
        .filter(entry => entry.isEngineerOneDays)
        .slice(0, 3)
        .map(({ entryUrl, imageUrl, title, summary }, i) => (
          <Article
            key={i}
            entryUrl={entryUrl}
            imageUrl={imageUrl}
            title={title}
            summary={summary}
          ></Article>
        ))}
    </>
  )
}

const Article = ({ entryUrl, imageUrl, title, summary }) => (
  <React.Fragment>
    <div className="onedays-card">
      <a href={entryUrl} target="_blank" rel="noopener">
        <img className="onedays-card-image" src={imageUrl} alt=""></img>
      </a>
      <div className="onedays-card-title">{title}</div>
      <div className="onedays-card-desc">{summary}</div>
      <Button
        className="read-more"
        href={entryUrl}
        rel="noopener"
        target="_blank"
      >
        記事を読む
      </Button>
    </div>
  </React.Fragment>
)

const Heading = styled.h3`
  color: #fff;
  text-align: center;
`

const OneDaysWrap = styled.div`
  width: 80%;
  margin: 32px auto 128px;

  ${customMedia.greaterThan("medium")`
    display: grid;
    margin: 32px auto 128px;
    grid-template-columns: repeat(2, 46%);
    grid-gap: 8%;
  `}

  ${customMedia.greaterThan("main")`
    grid-template-columns: repeat(4, 19%);
    margin: 32px auto;
  `}

  & .onedays-card {
    color: #fff;
    margin-bottom: 92px;
  }

  & .onedays-card-title {
    font-weight: bold;
    margin: 16px auto 8px;
  }

  & .onedays-card-image {
    max-width: 100%;
  }

  & .onedays-card-desc {
    font-size: 0.8rem;
  }

  & .read-more {
    margin: 16px auto;
    padding: 12px 32px;
    color: #000;
    text-align: center;
    pointer-events: auto;
    &:hover {
      text-decoration: none;
      color: #000;
    }
  }
`
