import React from "react"
import { Link } from "react-scroll"
import styled from "styled-components"
import LogoWhite from "../images/LogoWhite.svg"
import colors from "../utility/colors"
import { Menu } from "../modules/menu"
import customMedia from "../utility/media"
import { IconTwitter, IconConnpass } from "../images/Icons"

export const Header = () => (
  <Wrap>
    <Top>
      <img className="logo is-hidden" src={LogoWhite} alt="Opt Technologies" />
    </Top>
    <Social
      href="https://twitter.com/OptTechnologies"
      target="_black"
      rel="noopener"
    >
      <IconTwitter fill={colors.primary} />
    </Social>
    <Social href="https://opt.connpass.com/" target="_blank" rel="noopener">
      <IconConnpass fill={colors.primary} />
    </Social>
    <Link to="recruit" smooth={true} offset={-30}>
      <Recruit>RECRUIT</Recruit>
    </Link>
    <Menu />
  </Wrap>
)

const Recruit = styled.div`
  display: block;
  background: ${props => colors.attention};
  text-decoration: none;
  color: #fff;
  width: 120px;
  text-align: center;
  line-height: 50px;
  font-weight: bold;
  cursor: pointer;
`

const Wrap = styled.header`
  display: grid;
  grid-template-columns: 1fr 50px 50px 120px 50px;
  grid-template-rows: 50px;
  grid-column-gap: 8px;
  background: ${props => colors.primary};

  ${customMedia.greaterThan("main")`
    position: absolute;
    width: 100vw;
    background: transparent;
    z-index: 2;
  `}
`

const Social = styled.a`
  display: block;
  width: 50px;
  background: #fff;

  > svg {
    display: block;
    width: 24px;
    height: 24px;
    margin: auto;
    margin: 13px auto;
  }
`

const Top = styled.h1`
  .logo {
    display: block;
    height: 32px;
    margin: 9px 8px;
  }

  .logo.is-hidden {
    opacity: 0;
  }
`
