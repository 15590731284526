export const frontendItems = [
  "TypeScript",
  "Angular",
  "React",
  "Redux",
  "Vue.js",
  "jQuery",
  "JavaScript",
  "PptxGenJS",
  "Next.js",
  "Smarty",
]

export const backendItems = {
  Scala: ["Play Framework", "Spray Framework", "Sangria", "Spark"],
  Ruby: ["Ruby on Rails"],
  PHP: ["FuelPHP", "Laravel", "Highcharts", "CodeIgniter"],
  Java: ["Spring Boot"],
  Clojure: ["Duct", "Pedestal", "Lacinia"],
  Go: [],
  "Node.js": ["Express"],
  Python: [],
  "C#.NET": ["SpreadsheetGear"],
}

export const infraDevOpsItems = {
  "Amazon Web Services": [
    "Amazon Athena",
    "Amazon S3",
    "Amazon SQS",
    "Amazon RDS",
    "Aurora Serverless",
    "Amazon ECS",
    "AWS Lambda",
    "AWS Fargate",
    "AWS Elastic Beanstalk",
    "aws-cdk(CloudFormation)",
  ],
  "Google Cloud Platform": [
    "BigQuery",
    "Google App Engine",
    "Google Cloud SQL",
    "Google Cloud Storage",
    "Cloud Run",
    "Googleデータポータル",
  ],
  DevOps: [
    "GitHub",
    "ZenHub",
    "CircleCI",
    "Ansible",
    "Terraform",
    "Datadog",
    "Sentry",
    "Kubernetes",
    "AirFlow",
    "Mackerel",
    "DockerHub",
  ],
}

export const dataScienceItems = [
  "Python",
  "scikit-learn",
  "PyTorch",
  "NumPy",
  "pandas",
  "Jupyter Notebook",
  "TensorFlow",
  "Weights & Biases",
  "React",
  "Vue.js",
]
