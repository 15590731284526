import React from "react"
import styled from "styled-components"
import colors from "../utility/colors"
import ChallengeDigitalShift from "../images/photos/Challenge.png"
import customMedia from "../utility/media"
import InviewMonitor from "react-inview-monitor"

export const DigitalShift = () => (
  <DigitalShiftWrap id="mission">
    <Explanation>
      <h2>デジタルシフトに挑戦する</h2>
      <h3>オプトの目指すデジタルシフトとは</h3>
      <InviewMonitor classNameInView="is-inview">
        <p className="text">
          オプトはマーケティングを中心とした産業や社会のデジタルシフトを目指しています。第三次産業革命以降、デジタル産業革命は今なお加速度を増しながら継続中です。マーケティングを中心に様々なプロセスや基盤をデジタル化することによって、業務効率が改善し価値創造も加速していきます。そして、デジタル化対応した企業が多ければ多いほど、経済力、国力は向上し、企業も国も世界的な成長に貢献できると考えています。
        </p>
      </InviewMonitor>
      <InviewMonitor classNameInView="is-inview">
        <p className="text">
          オプトは平たく言ってしまえばインターネット広告代理店ですが、”Innovation
          Agency”
          を掲げ、デジタルシフトによって社会や世界を良くすることに本気で挑戦しています。エンジニアリング部門である
          Opt Technologies
          ではマーケティングをデジタルシフトさせるべく、広告運用プロセスの自動化やAIによる高度化などに取り組んでいます。ビジネスサイドと協働することで
          Opt Technologies
          の確かな技術を持ったエンジニアが、きっとデジタルシフトの立役者になるでしょう。
        </p>
      </InviewMonitor>
    </Explanation>
    <ImageWrap>
      <Image></Image>
      <Challenge>
        <InviewMonitor classNameInView="is-inview">
          <p className="challenge-text">
            CHALLENGE
            <br />
            DIGITAL
            <br />
            SHIFT
          </p>
        </InviewMonitor>
      </Challenge>
    </ImageWrap>
  </DigitalShiftWrap>
)

const DigitalShiftWrap = styled.div`
  ${customMedia.greaterThan("main")`
    height: 100vh;
    position: relative;
    z-index: 0;
  `}

  ${customMedia.greaterThan("large")`
    height: 86vh;
    position: relative;
  `}
`

const Explanation = styled.div`
  margin: 16px auto 0;
  padding: 64px 36px 36px;
  width: 96%;
  color: white;
  background: ${props => colors.primary};
  position: relative;

  & .text {
    font-size: 0.9rem;
    text-align: justify;
    opacity: 0;
  }

  & .is-inview {
    & .text {
      opacity: 1;
      transition: opacity 0.2s 0.2s;
    }
  }

  ${customMedia.greaterThan("main")`
    position: absolute;
    z-index: 2;
    padding: 80px 48px 48px;
  `}

  &::before {
    content: "";
    width: 60px;
    height: 15px;
    background: ${props => colors.attention};
    position: absolute;
    top: 0;
  }

  ${customMedia.greaterThan("main")`
    width: 40vw;
    height: auto;
    min-width: 500px;
    max-height: 84vh;
    overflow-y: hidden;
    margin-left: 5vw;
    margin-top: 12vh;
  `}

  @media screen and (min-width: 960px) and (max-height: 640px) {
    padding: 24px 16px 16px;

    h2 {
      font-size: 1rem;
    }
    h3 {
      font-size: 0.9rem;
    }

    & .text {
      font-size: 0.7rem;
      text-align: justify;
      opacity: 0;
    }
  }
`

const ImageWrap = styled.div`
  position: relative;
  padding-bottom: 25vh;
  overflow: hidden;
  ${customMedia.greaterThan("medium")`
    padding-bottom: 45vh;
  `}
  ${customMedia.greaterThan("main")`
    padding-bottom: 100vh;
  `}
`

const Image = styled.div`
  background-image: url(${ChallengeDigitalShift});
  background-size: cover;
  width: 96vw;
  margin: 0 auto;
  height: 60vw;
  ${customMedia.greaterThan("main")`
    max-height: 60vh;
    position: absolute;
    right: 0;
    width: 60vw;
  `}
`

const Challenge = styled.div`
  position: absolute;
  width: 100vw;
  z-index: 2;
  text-align: center;

  & .challenge-text {
    font-family: "Gotham", sans-serif;
    font-weight: 900;
    font-size: 3.6rem;
    line-height: 3rem;
    color: ${props => colors.complement};
    transform: rotate(-360deg);
    width: 100%;
    top: -180px;
    right: 20vw;
    margin: 0 auto;
    letter-spacing: -4px;
    font-style: italic;

    ${customMedia.greaterThan("medium")`
        font-size: 5rem;
        line-height: 5rem;
      `}

    ${customMedia.greaterThan("main")`
        font-size: 8rem;
        line-height: 6rem;
        letter-spacing: -8px;
      `}
  }
  & .is-inview {
    .challenge-text {
      width: 100%;
      transform: rotate(-18deg);
      transition: transform 0.6s 0.4s;
    }
  }

  ${customMedia.greaterThan("main")`
    bottom: 5vh;
    right: -20vw;
  `}

  ${customMedia.greaterThan("large")`
    bottom: 20vh;
  `}
`
