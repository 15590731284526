import React from "react"
import styled from "styled-components"
import VisionA from "../images/vision/agile.svg"
import VisionB from "../images/vision/biz-oriented.svg"
import VisionC from "../images/vision/collaborative.svg"
import { Headline, HeadlineJp } from "../modules/headlines"
import customMedia from "../utility/media"
import colors from "../utility/colors"
import InviewMonitor from "react-inview-monitor"
import { AnimatedWrap } from "../modules/animatedwrap"

export const Vision = () => (
  <AnimatedWrap>
    <InviewMonitor classNameInView="is-inview">
      <VisionWrap className="wrap">
        <Headline id="vision">VISION</Headline>
        <HeadlineJp>ビジョン</HeadlineJp>
        <LabelTitle id="vision">
          変化に適応するエンジニア組織へ Opt Technologiesの "ABC"
        </LabelTitle>
        <AreaWrap className="first">
          <CardWrap>
            <ImageWrap>
              <img src={VisionA} alt="Agile" />
            </ImageWrap>
            <Title>Agile (アジャイル)</Title>
            <Desc>不確実性を前提にアジャイルに意思決定を繰り返す</Desc>
          </CardWrap>
        </AreaWrap>
        <AreaWrap className="secound">
          <CardWrap>
            <ImageWrap>
              <img src={VisionB} alt="Biz-oriented" />
            </ImageWrap>
            <Title>Biz-oriented (ビジネス指向)</Title>
            <Desc>事業、会社、社会に自ら関わり価値を生み出す</Desc>
          </CardWrap>
        </AreaWrap>
        <AreaWrap className="third">
          <CardWrap>
            <ImageWrap>
              <img src={VisionC} alt="Collaborative" />
            </ImageWrap>
            <Title>Collaborative (協調的)</Title>
            <Desc>自律・分散的でありながら協調的な組織/システムへ</Desc>
          </CardWrap>
        </AreaWrap>
      </VisionWrap>
    </InviewMonitor>
  </AnimatedWrap>
)

const AreaWrap = styled.div`
  max-width: 1200px;
  margin: 20px auto 0 20px;

  ${customMedia.greaterThan("main")`
    display: grid;
    align-items: start;
    margin: 0 auto 0 30%;
  `}

  &.first {
    margin-top: 34px;
  }

  &.third {
    margin-bottom: 34px;
  }
`

const CardWrap = styled.div`
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 32px;
  ${customMedia.greaterThan("main")`
    grid-template-columns: 100px 1fr;
    grid-template-rows: repeat(2, auto);
  `}
`

const LabelTitle = styled.h3`
  text-align: center;
  margin-top: 48px;
  margin-bottom: 16px;
  font-size: 1.4rem;
`

const AreaImage = styled.img`
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 0 auto;
  ${customMedia.greaterThan("main")`
    width: 50%;
    margin: 0 auto;
  `}
`

const VisionWrap = styled.div`
  position: relative;
  padding: 48px 0 0;
  background: linear-gradient(
    -25deg,
    #fff 50%,
    ${props => colors.complement} 50%,
    ${props => colors.complement} 80%,
    #fff 80%
  );
`

const ImageWrap = styled.div`
  background: ${props => colors.primary};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 25px;
  }

  ${customMedia.greaterThan("main")`
    width: 80px;
    height: 80px;

    & svg {
      width: 50px;
    }

    grid-row: span 2;
  `}
`

const Title = styled.div`
  font-weight: bold;
  ::first-letter {
    color: #ff0013;
    font-size: 2rem;
  }
  ${customMedia.greaterThan("main")`
    font-size: 1.4rem;
  `}
`

const Desc = styled.div`
  grid-column: span 2;
  ${customMedia.greaterThan("main")`
    grid-column: auto;
  `}
`
