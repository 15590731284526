import styled from "styled-components"
import colors from "../utility/colors"

export const Footer = styled.footer`
  width: 100%;
  display: block;
  text-align: center;
  background: ${props => colors.primary};
  color: #fff;
  padding: 48px 8px 64px;
`
