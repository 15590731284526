import React from "react"
import { Headline, HeadlineJp } from "../modules/headlines"
import { Button } from "../modules/buttons"
import colors from "../utility/colors"
import styled from "styled-components"
import LogoOpt from "../images/LogoOpt.svg"
import LogoDH from "../images/LogoDH.svg"
import customMedia from "../utility/media"

const Contact = () => (
  <ContactWrap>
    <Headline id="contact" color="#fff">
      CONTACT
    </Headline>
    <HeadlineJp color="#fff">お問い合わせ</HeadlineJp>
    <Button
      className="contact-button"
      href="https://www.opt.ne.jp/contact_detail/id=28"
      target="_blank"
      width="90%"
    >
      Opt Technologiesへのお問い合わせ
    </Button>
    <Title>LINK</Title>
    <LinkButtons>
      <Button
        className="button"
        href="https://www.opt.ne.jp/"
        target="_blank"
        rel="noopener"
      >
        <img className="logo" src={LogoOpt} alt="Opt" />
      </Button>
      <Button
        className="button"
        href="https://digital-holdings.co.jp/"
        target="_blank"
        rel="noopener"
      >
        <img className="logo" src={LogoDH} alt="Digital Holdings" />
      </Button>
    </LinkButtons>
  </ContactWrap>
)

const ContactWrap = styled.div`
  background: ${props => colors.primary};
  padding: 48px 0;

  & .contact-button {
    text-align: center;
    margin: 64px auto 32px;
    color: #000;
    text-decoration: none;
  }
`

const Title = styled.h3`
  text-align: center;
  color: white;
`

const LinkButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 46%);
  grid-column-gap: 8%;
  width: 90%;
  max-width: 480px;
  margin: 12px auto 0;

  & .button {
    padding: 8px 40px;
    ${customMedia.greaterThan("medium")`
      padding: 8px 56px;
    `}
  }

  & .logo {
    width: 100%;
  }
`

export default Contact
