import React from "react"
import styled from "styled-components"
import customMedia from "../utility/media"
import colors from "../utility/colors"
import { systems, env, consultants } from "./workstyle-data.jsx"

export const SystemsCard = () => {
  return systems.map((sys, i) => (
    <CardWrap key={`${sys.name.toLowerCase()}_${i}`}>
      <ImageWrap>{sys.image}</ImageWrap>
      <Title>{sys.name}</Title>
      <Desc>{sys.contents}</Desc>
    </CardWrap>
  ))
}

export const EnvCard = () => {
  return env.map((env, i) => (
    <CardWrap key={`${env.name.toLowerCase()}_${i}`}>
      <ImageWrap>{env.image}</ImageWrap>
      <Title>{env.name}</Title>
      <Desc>{env.contents}</Desc>
    </CardWrap>
  ))
}

export const ConsultantsCard = () => {
  return consultants.map((consultant, i) => (
    <CardWrapConsultant key={`${consultant.name.toLowerCase()}_${i}`}>
      <img src={consultant.image} alt={consultant.name} />
      <TitleConsultant>{consultant.name}</TitleConsultant>
      <DescConsultant>{consultant.contents}</DescConsultant>
      <Domain>
        <div className="title">担当領域</div>
        <div>{consultant.domain}</div>
      </Domain>
    </CardWrapConsultant>
  ))
}

const CardWrap = styled.div`
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 32px;
  ${customMedia.greaterThan("main")`
    grid-template-columns: 100px 1fr;
    grid-template-rows: repeat(2, auto);
  `}
`

const CardWrapConsultant = styled(CardWrap)`
  grid-template-columns: 12vw 1fr;
  margin-top: 32px;
  font-size: 0.9rem;

  & img {
    width: 100%;
    margin-right: 16px;
    grid-row: span 3;
  }

  ${customMedia.greaterThan("main")`
    grid-template-columns: 260px 1fr;
    line-height: 26px;
    align-items: start;
  `}
`

const ImageWrap = styled.div`
  background: ${props => colors.primary};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 25px;
  }

  ${customMedia.greaterThan("main")`
    width: 80px;
    height: 80px;

    & svg {
      width: 50px;
    }

    grid-row: span 2;
  `}
`

const Title = styled.div`
  font-weight: bold;
  ${customMedia.greaterThan("main")`
    font-size: 1.2rem;
  `}
`

const TitleConsultant = styled(Title)`
  font-size: 1.2rem;
`

const Desc = styled.div`
  grid-column: span 2;
  ${customMedia.greaterThan("main")`
    grid-column: auto;
  `}
`

const DescConsultant = styled(Desc)``

const Domain = styled.div`
  grid-column: span 2;
  font-weight: bold;

  .title {
    font-size: 1rem;
    font-weight: bold;
    margin: 8px 0 0;
  }

  ${customMedia.greaterThan("main")`
    grid-column: auto;
  `}
`
