import React from "react"
import styled from "styled-components"
import colors from "../utility/colors"
import Pick from "../images/Pick.svg"

export class ScrollButton extends React.Component {
  constructor() {
    super()
    this.state = {
      intervalId: 0,
    }
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId)
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx)
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    )
    this.setState({ intervalId: intervalId })
  }

  render() {
    return (
      <>
        <Gotop
          onClick={() => {
            this.scrollToTop()
          }}
        >
          <div>
            <p>TOP</p>
          </div>
        </Gotop>
      </>
    )
  }
}

const Gotop = styled.a`
  padding: 4px;
  width: 72px;
  height: 72px;
  position: fixed;
  z-index: 0;
  right: 12px;
  bottom: 12px;
  font-style: italic;
  font-weight: bold;
  cursor: pointer;

  > div {
    width: 100%;
    height: 100%;
    color: #fff;
    background: ${props => colors.attention};
    mask-image: url(${Pick});
    mask-repeat: no-repeat;
    mask-size: contain;
    text-align: center;
    line-height: 56px;
    transition: transform linear 0.2s, line-height linear 0.3s;
  }

  & p {
    margin-left: -3px;
  }

  &:hover > div {
    transform: rotateX(180deg);
    line-height: 62px;
    transition: transform linear 0.2s, line-height linear 0.3s;

    > p {
      transform: rotateX(180deg);
      margin-bottom: 0;
    }
  }
`

export const Button = styled.a`
  width: ${props => props.width};
  max-width: 480px;
  display: block;
  border-left: 4px solid ${props => colors.attention};
  padding: 16px 32px;
  background: #fff;
  margin: ${props => props.margin};
  text-align: ${props => props.align};
`

Button.defaultProps = {
  width: "100%",
  margin: "0 auto",
  align: "left",
}
