import ImgOnesData from "../images/product/onesdata.jpg"
import ImgFeedTerminal from "../images/product/feedterminal.jpg"
import ImgPerformancePlatform from "../images/product/performanceplatform.jpg"
import ImgReportFactory from "../images/product/reportfactory.jpg"
import ImgAms from "../images/product/ams.jpg"
import ImgAdpoke from "../images/product/adpoke.jpg"
import ImgForesee from "../images/product/foresee.jpg"
import ImgTaskDriver from "../images/product/taskdriver.jpg"
import ImgOptEditor from "../images/product/opteditor.jpg"
import ImgAdteras from "../images/product/adteras.jpg"
import ImgCreativeDrive from "../images/product/creativedrive.jpg"
import ImgAlloSim from "../images/product/allosim.jpg"
import ImgNoLogo from "../images/product/no-product-logo.jpg"

export const datamarketing = [
  {
    name: "ONE’s Data",
    image: `${ImgOnesData}`,
    desc: "CDP構築、O2Oデータハブ",
    detail:
      "アプリ・Web等のオンラインデータと購買・マスタ情報等のオフラインデータを統合し、CDP（Customer Data Platform）としての基盤を構築しつつ、広告媒体との連携をするツールです。Cookieレス世界における広告効果計測を再定義し、データ分析やマーケティング最適化を担います。",
    tech:
      "外部計測ツールからの大きなトラフィックに耐えうる計測サーバと、その大規模データの解析に耐えうるバックエンド、そしてわかりやすいレポーティングと定型業務の効率化を支援する管理画面があります。計測サーバはGo言語で、管理画面はRoRで開発されています。BigQueryを中心としたデータ基盤としても利用されており、CDPや広告媒体との連携を行うためのデータを管理しています。",
    tools:
      "(新) Go / Next.js / React / Node.js / Python / Google Cloud Platform / BigQuery / Kubernetes",
    tools2:
      "(旧) Ruby / Ruby on Rails / Google Cloud Platform / BigQuery / AirFlow / React / Node.js / Python",
    link: "https://onesdata.com/",
  },
  {
    name: "FeedTerminal",
    image: `${ImgFeedTerminal}`,
    desc: "自由度の高いデータフィードの最適化・管理ツール",
    detail:
      "広告主の商品のマスタデータ（データフィード）をインポートし、様々な加工や最適化を行った上で、外部のデータフィード広告メディアへエクスポートできるプロダクトです。データの結合やフィルタなど自由度が高いことが特長の一つで、Criteo、Googleなど国内外の主要媒体に対応しています。",
    tech:
      "データフィードの最適化や加工については、Sparkをベースとしたスケーラブルで柔軟な大規模データ処理を行っています。詳細な設定を行う管理画面はReactをベースとしており、スムースな操作が可能です。",
    tools:
      "(新) Go / Next.js / React / Node.js / Google Cloud Platform / BigQuery / Cloud Run / Kubernetes",
    tools2:
      "(旧) Scala / Play Framework / Amazon Web Services / Spark / Terraform / TypeScript / React",
    link: "https://feed-terminal.jp/",
  },
]

export const operation = [
  {
    name: "Performance Platform",
    image: `${ImgPerformancePlatform}`,
    desc:
      "広告媒体データおよび広告効果計測ツールデータを蓄積・提供する基盤システム",
    detail:
      "各種広告媒体データと広告効果計測ツールデータを蓄積し、各種社内プロダクトにデータを返却するAPIを提供しています。主要な広告媒体データは媒体APIを用いて毎日自動で取得しており、工数削減に役立っています。また、広告媒体データと広告効果計測ツールデータの紐付けや着地見込の計算などを基盤システムで一元的に行うことにより、各プロダクトはその他の機能の開発に注力することができます。",
    tech:
      "データ蓄積処理では、広告媒体データ取得API、S3ファイル連携、RPAによるアップロードなど広告媒体毎に異なる取得方法、ファイル形式で取得し、それらをLambdaなどを用いて整形し、S3およびRDSに保存しています。社内向けのAPIとしてはSpring Bootを利用しており、様々なリクエストパターンに応じてRDSでデータを集計し返却しています。また、S3ベースのデータレイクに蓄積したデータは統計分析用にAthenaでクエリすることも可能です。",
    tools:
      "Java / Scala / Python / Node.js / AWS (Lambda, S3, RDS, Elastic Beanstalk, Fargate, Athena, etc.) / Spring Boot",
    link: "",
  },
  {
    name: "Report Factory",
    image: `${ImgReportFactory}`,
    desc: "レポート生成システム",
    detail:
      "レポート作成（Excel）にかかる作業工数を削減するためのシステムです。PerformancePlatformと連携し、各種媒体および計測ツールデータを必要な形式で取得してレポートを生成します。提出先のフォーマットに合せたレポートとフォーマットへ加工する前のデータ、2パターンの出力に対応しています。",
    tech:
      "レポート生成に必要な設定を行うWebシステムと実際にExcelレポートを生成するバッチ処理で構成されています。日々大量のレポートを作成することを求められるため、Excelを高速処理できるコンポーネントSpreadsheetGearを利用しています。",
    tools:
      "(新) Amazon Web Services / PHP / Laravel / Vue.js / JavaScript / C#.NET / SpreadsheetGear",
    tools2:
      "(旧) Amazon Web Services / PHP / FuelPHP / jQuery / C#.NET / SpreadsheetGear",
    link: "",
  },
  {
    name: "アムズ",
    image: `${ImgAms}`,
    desc: "広告媒体アカウント管理システム",
    detail:
      "媒体に発行されている広告用アカウントと、広告運用作業に必要な各種情報をマスタ管理します。各プロダクトがアムズで管理された情報を利用することで、ユーザーは共通データを複数プロダクトで利用可能になり、エンジニアはプロダクト特有機能の開発に注力できます。",
    tech:
      "旧プロダクトから新プロダクトへの移行を徐々に進めており、新プロダクトではGraphQLのAPIにより柔軟なデータ取得を可能にし、マスタ管理画面は各プロダクトから呼び出し可能なコンポーネントも提供しています。",
    tools:
      "(新) Amazon Web Services / Clojure / Duct / Pedestal / Lacinia / Go / TypeScript / Vue.js",
    tools2: "(旧) Amazon Web Services / PHP / FuelPHP / Smarty / jQuery",
    link: "",
  },
  {
    name: "アドポケ",
    image: `${ImgAdpoke}`,
    desc: "運用型広告向け予算管理システム",
    detail:
      "各広告媒体のアカウントを予算カテゴリと言う形で一元管理し、実際の運用に合わせた予算管理を支援します。利用金額の急変動や設定予算に近づいた際のアラートにも対応し、より個々の運用に合わせた予算管理を行えます。",
    tech:
      "旧プロダクトから新プロダクトへ移行を進めており、新プロダクトではフロントエンドにVue.jsを利用し、横断的に見たい予算カテゴリの階層をスムーズに表示させ、数値の素早い表示のためにバッチ処理（Laravel）で事前集計データを用意したり、個別の最新数値を見るニーズがあるためリアルタイムでデータ集計するなど、用途に合わせたデータ提供をREST API（Laravel）で実現しています。",
    tools: "(新) Amazon Web Services / PHP / Laravel / Vue.js / JavaScript",
    tools2: "(旧) Amazon Web Services / PHP / FuelPHP / Smarty / jQuery",
    link: "",
  },
  {
    name: "Foresee",
    image: `${ImgForesee}`,
    desc: "コンサル向け運用実績モニタリングシステム",
    detail:
      "運用実績を売上に直結する形でアウトプットし、コンサル業務の工数削減・品質向上・施策推進による売上拡大を目指すことができます。",
    tech:
      "表示にHighchartsを利用し、インタラクティブに動作するチャートが生成されます。用途に合わせたチャートを複数準備することで様々な業務への対応を行っています。また、定型のレポートやチャートをPptxGenJSでPPTファイルで出力します。",
    tools:
      "(新) Amazon Web Services / PHP / Laravel / Vue.js / JavaScript / Express / PptxGenJS",
    tools2:
      "(旧) Amazon Web Services / PHP / FuelPHP / Smarty / jQuery / Highcharts",
    link: "",
  },
  {
    name: "Task Driver",
    image: `${ImgTaskDriver}`,
    desc: "社内依頼管理システム",
    detail:
      "営業活動や広告運用に関する社内の依頼を一元管理するシステムです。ステータスの通知や依頼の進捗を可視化することにより対応漏れを防止します。",
    tech:
      "依頼に関するユーザー間のコミュニケーションを円滑にするため、フロントエンドにAngularを利用してチャット風のUIを持ったインタラクティブなSPAを実現しています。サーバサイドはPHPによるREST APIとClojureによるバッチで構成されています。",
    tools:
      "PHP / CodeIgniter / Clojure / Amazon Web Services / TypeScript / Angular",
    link: "",
  },
  {
    name: "Opt Editor",
    image: `${ImgOptEditor}`,
    desc: "媒体横断支援ツール",
    detail:
      "あらゆるデータや操作をつなぎ、様々な方法でのアウトプットが柔軟に行えるマルチツールです。クライアント毎の細かな要求、運用に対応するだけでなく、新しい施策のPOCへの利用、媒体を横断した施策、全アカウント対象としたアラートなど、運用改善、ミス対策、個別施策と幅広く活用され、必要なニーズをのがしません。",
    tech:
      "外部の広告媒体側のアップデートやニーズの変化に柔軟に対応していく必要性から、広告媒体連携などのバッチ処理はJavaにより実装することでスループットや安定性を確保し、画面はPHPで実装することで素早いUI変更に対応できる作りになっています。\n" +
      "媒体毎に異なる仕様、エラーハンドリング、アクセス上限、バージョンアップなど、大量のデータをさばきつつ、状況に応じて変わる媒体変化に素早く対応できる仕組みづくりを目指しています。",
    tools: "Amazon Web Services / PHP / Java / jQuery",
    link: "",
  },
  {
    name: "NOE",
    image: `${ImgNoLogo}`,
    desc: "広告入稿Editorツール",
    detail:
      "YG一括で各階層のステータスなどの変更予約が可能なシステムです。ラベルや名称等で指定することも可能で、メンテナンス等の一時的な切り替えや、キャンペーン等の一時的な有効など、媒体ではできない柔軟なスケジュール設定を可能とすることで、運用をサポートします。",
    tech:
      "当時は利用実績が少なかった・無かった比較的新しい技術を大胆に取り入れ、各種AWSサービスやCI/CDだけでなくプログラミング言語でさえ豊富に利用した作りになっています。広告媒体からのデータ同期やステータス自動変更は、役割ごとに細かく分かれたバッチ処理をインフラ (AWSサービス) 上で組み合わせることで実現しています。",
    tools:
      "Amazon Web Services / Scala / Clojure / Duct / Pedestal / Python / TypeScript / Vue.js / Terraform",
    link: "",
  },
  {
    name: "Adteras",
    image: `${ImgAdteras}`,
    desc: "広告配信実績モニタリングダッシュボード",
    detail:
      "顧客向けの広告配信実績の各種レポートをオンライン上で提供し、従来のExcel形式のファイル管理の煩雑さを解消したり、日々のレポート作成や送付作業をなくすことで実績把握リードタイムの短縮を図ります。",
    tech:
      "実績情報をバッチで収集・集計し、グラフや表など有用な形式で表示するアプリケーションです。社内運用だけでなく顧客による操作も念頭に置いているため、パフォーマンスを含むUXには注意が払われています。\n" +
      "UIデザインに関する記事は下部の「Adteras 紹介ページ」ボタンからご覧ください。",
    tools:
      "Amazon Web Services / Ruby / Ruby on Rails / TypeScript / React / Redux / aws-cdk(CloudFormation) / ECS Fargate / RDS Aurora Serverless / SQS / S3 / Sentry / Mackerel / CircleCI / DockerHub / Zenhub",
    link: "https://tech-magazine.opt.ne.jp/entry/2022/02/18/104800",
  },
  {
    name: "Creative Drive",
    image: `${ImgCreativeDrive}`,
    desc:
      "入稿効率化とナレッジマネジメント効率化を担うクリエイティブデータベース",
    detail:
      "クリエイティブを一元管理するプロダクトです。社内の他の入稿ツールやレポートツールと連携し、入稿作業効率化やナレッジマネジメント効率化を支えています。",
    tech:
      "クリエイティブデータファイル及び広告を登録する管理画面と、各プロダクトと連携するAPI・バッチ処理で構成されています。GCSにファイルをアップロードする際、署名付きURLと並列処理を採用することで、複数ファイルのアップロード速度を高速化しています。",
    tools:
      "Ruby / Ruby on Rails / Google App Engine / Google Cloud SQL / Google Cloud Storage / Angular",
    link: "",
  },
  {
    name: "Edikari",
    image: `${ImgNoLogo}`,
    desc: "入稿自動化Editorツール",
    detail:
      "入稿用のデータ紐付けを広告媒体横断的に行うシステムです。広告媒体ごとの違いを吸収し、どの広告媒体に関するデータであっても同じ操作で作業できることで、入稿業務の効率化・標準化をサポートします。",
    tech:
      "GraphQL APIをはじめとする、当時は利用実績が少なかった・無かった比較的新しい技術を大胆に取り入れた作りになっています。また、マイクロサービスを意識して作られたほかの各種社内ツールを利用する側という位置づけでもあり、各ツールから取得したデータ群をAPIサーバが加工・集約して画面へ渡すBackend For Frontend的な役割分担があります。",
    tools:
      "Amazon Web Services / Scala / Sangria / Python / TypeScript / Vue.js / Terraform",
    link: "",
  },
  {
    name: "optter",
    image: `${ImgNoLogo}`,
    desc: "Twitterのツイート作成システム",
    detail:
      "Twitterのツイートを作成するシステムです。手作業で行っていたツイート作成をExcelファイルで作成できるため運用効率だけでなく、ミスを軽減にも貢献します。",
    tech:
      "Excelファイルで作成、確認を行うことで、画面はアップロードとダウンロードのシンプルな画面にしました。サーバサイドはScalaによるREST APIです。バッチを持たないことで、こちらもシンプルな構成になっています。オンメモリでキャッシュを管理することで、Redisなどのコスト削減をしました。",
    tools: "Amazon Web Services / Scala / Angular / TypeScript",
    link: "",
  },
  {
    name: "facebook tool",
    image: `${ImgNoLogo}`,
    desc: "Facebookの広告ステータス管理システム",
    detail:
      "Facebookの広告ステータスを管理するシステムです。広告効果の高い広告を自動で表示し、効果の低い広告は非表示にします。",
    tech:
      "サーバー側はAkkaリアクティブシステムで、大量のデータを分散してローコストで処理できます。DynamoDBを使用することでコスト削減をしました。",
    tools: "Amazon Web Services / Scala / React / TypeScript",
    link: "",
  },
  {
    name: "opzon",
    image: `${ImgNoLogo}`,
    desc: "Amazonの入札額調整システム",
    detail:
      "Amazonの入札額の調整システムです。手作業で行っていた入札額の調整をルールベースで自動実行します。",
    tech:
      "管理画面から、複数のルールを選択して調整された入札額の最終額を確認することができます。サーバサイドはScalaによるREST APIです。オンメモリでキャッシュを管理することで、Redisなどのコスト削減をしました。バッチにServerlessを使用することで、Lambdaを一括管理できます。",
    tools: "Scala / Vue.js / TypeScript / Serverless / Datadog",
    link: "",
  },
]

export const ai = [
  {
    name: "AlloSim",
    image: `${ImgAlloSim}`,
    desc: "広告媒体ごとの最適な予算配分をシミュレーションするAIツール",
    detail:
      "媒体ごとの最適な予算配分をシミュレーションするツールです。\n" +
      "予算や期間、媒体など各種条件を入力すると、コンバージョン（CV）数の予測結果がグラフ付きで返ってきます。複数の媒体を選択すれば、獲得効率を踏まえて最適な予算配分（アロケーション）を算出して表示します。\n" +
      "これにより、シミュレーション／アロケーションにかかる業務時間を削減します。",
    tech:
      "予測アルゴリズムの改善（シミュレーション精度の向上）やツール用途の拡大を目指し、自社内および産学連携でのR&Dを継続的に行っています。\n例としては、ミクロ経済学（限界費用など）、経営科学（不確実性下の予算配分モデルなど）などの理論や確率評価/制御、深層学習などの技術を応用しています。",
    tools:
      "Python / NumPy / Numpyro / Pandas / SciPy / Plotly / seaborn / Matplotlib / Poetry / Amazon Web Services / Terraform / Flask",
    link: "",
  },
]

export const managementstrategy = [
  {
    name: "経営モニタリングシステム",
    image: `${ImgNoLogo}`,
    desc: "経営戦略分析基盤",
    detail:
      "全社経営戦略に沿った管理会計・KPIの実績情報をタイムリーに可視化し、迅速な意思決定に活かすための情報基盤システムです。",
    tech:
      "GCPのCloud Data Fusionにより専門知識のあるエンジニアでなくても直感的に分かりやすいUIでデータ処理パイプラインを構築運用しています。",
    tools:
      "SQL / JavaScript / Python / Google Cloud Platform (Cloud Data Fusion, BigQuery, etc.) / Googleデータポータル",
    link: "",
  },
]
