import React from "react"

export class IconFacebook extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill={this.props.fill}
      >
        <path
          id="facebook"
          data-name="facebook f"
          d="M37.969,14.941A10.109,10.109,0,0,0,32.959,16a4.867,4.867,0,0,0-2.373,2.637,13.011,13.011,0,0,0-.527,4.043V33.75H45l-2.109,16H30.059V90H13.535V49.746H0v-16H13.535V21.094q0-10.02,5.625-15.557T34.1,0a87.065,87.065,0,0,1,12.3.7V14.941Z"
          transform="translate(25 3)"
        />
      </svg>
    )
  }
}

export class IconTwitter extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill={this.props.fill}
      >
        <path
          id="twitter"
          data-name="twitter"
          d="M80.828,18.248v2.389a53.484,53.484,0,0,1-3.439,18.535,53.137,53.137,0,0,1-10.08,16.911A51.845,51.845,0,0,1,50.876,68.408a50.928,50.928,0,0,1-22.6,4.777,50.874,50.874,0,0,1-15-2.2A53.641,53.641,0,0,1,0,64.873q1.051.1,2.15.191t2.245.1a36.048,36.048,0,0,0,12.325-2.1,38.184,38.184,0,0,0,10.605-5.828,18.3,18.3,0,0,1-17.2-12.8,8.806,8.806,0,0,0,1.672.239q.908.048,1.768.048a21.654,21.654,0,0,0,2.484-.143,18.659,18.659,0,0,0,2.389-.43,18.459,18.459,0,0,1-10.557-6.4A17.729,17.729,0,0,1,3.631,25.987V25.8A21.006,21.006,0,0,0,7.6,27.373a18.508,18.508,0,0,0,4.347.717,18.466,18.466,0,0,1-5.971-6.545,17.964,17.964,0,0,1-2.245-8.838,17.826,17.826,0,0,1,.669-4.92A17.181,17.181,0,0,1,6.306,3.439a47.594,47.594,0,0,0,7.6,7.6,56.966,56.966,0,0,0,9.076,5.924A50.918,50.918,0,0,0,33.2,20.971a51.7,51.7,0,0,0,11.131,1.768,12.161,12.161,0,0,1-.382-2.1q-.1-1.051-.1-2.1a18.341,18.341,0,0,1,1.433-7.213,17.887,17.887,0,0,1,3.965-5.876,19.7,19.7,0,0,1,5.876-3.965A17.5,17.5,0,0,1,62.293,0a17.993,17.993,0,0,1,7.5,1.576,18.373,18.373,0,0,1,5.971,4.252,33.5,33.5,0,0,0,6.115-1.72,41.636,41.636,0,0,0,5.637-2.675,18.606,18.606,0,0,1-3.153,5.78,18.515,18.515,0,0,1-4.968,4.347,34.89,34.89,0,0,0,5.446-1A32.117,32.117,0,0,0,90,8.694,35.912,35.912,0,0,1,85.844,13.9,35.83,35.83,0,0,1,80.828,18.248Z"
          transform="translate(3 11)"
        />
      </svg>
    )
  }
}

export class IconConnpass extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill={this.props.fill}
      >
        <path d="M85,48.77a16.62,16.62,0,0,1,10.2-15.42L96,12.56c-33.61,10.27-64.41,9.22-96,0L.83,33.35A16.63,16.63,0,0,1,11,48.77,16.65,16.65,0,0,1,2,63.63L.8,83.44c33.87-5,63.64-6.08,94.4,0L94,63.63A16.64,16.64,0,0,1,85,48.77ZM61.26,64.36a15.13,15.13,0,0,1-3.87,3.41A15.9,15.9,0,0,1,53,69.63a17.67,17.67,0,0,1-4.42.58,20.07,20.07,0,0,1-4.7-.56,17.87,17.87,0,0,1-4.33-1.72A13.72,13.72,0,0,1,36,65.08a12.41,12.41,0,0,1-2.43-4,14.59,14.59,0,0,1-.91-5.19V43.11a14.59,14.59,0,0,1,.91-5.19,12.51,12.51,0,0,1,2.43-4A13.87,13.87,0,0,1,39.54,31a17.1,17.1,0,0,1,4.33-1.72,19.57,19.57,0,0,1,4.7-.57,17,17,0,0,1,4.44.55,15.74,15.74,0,0,1,4.42,1.81,13.25,13.25,0,0,1,3.73,3.38,14.41,14.41,0,0,1,2.43,5.22l-9.12,2.89a5.5,5.5,0,0,0-3.68-3.75,6.87,6.87,0,0,0-2.58-.3,6.59,6.59,0,0,0-2.43.66A4.88,4.88,0,0,0,44,40.7,4.1,4.1,0,0,0,43.27,43V55.8a4.38,4.38,0,0,0,.7,2.4,4.68,4.68,0,0,0,1.77,1.59,6,6,0,0,0,2.42.68,6.44,6.44,0,0,0,2.59-.34,6,6,0,0,0,2.32-1.45A6.49,6.49,0,0,0,54.7,56l9.35,3.14A16.76,16.76,0,0,1,61.26,64.36Z" />
      </svg>
    )
  }
}

export class IconTimes extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64.72 64.71"
        fill={this.props.fill}
      >
        <path
          className="cls-1"
          d="M32.36,0A32.36,32.36,0,1,0,64.72,32.35,32.39,32.39,0,0,0,32.36,0Zm0,56.21V32.35L11.71,20.43A23.85,23.85,0,1,1,32.36,56.21Z"
        />
      </svg>
    )
  }
}

export class IconPc extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 74.31 76.24"
        fill={this.props.fill}
      >
        <path
          className="cls-1"
          d="M45.44,33.33V0L0,26.24V59.57L28.87,76.24,74.31,50ZM8.5,54.66V31.15L36.94,14.73V38.24L57.3,50,28.87,66.42Z"
        />
        <polygon
          className="cls-1"
          points="12.45 52.38 33 40.52 33 21.25 12.45 33.12 12.45 52.38"
        />
      </svg>
    )
  }
}

export class IconGrade extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 79.6 77.57"
        fill={this.props.fill}
      >
        <path
          className="cls-1"
          d="M37.08,21.22,0,58.3v-12L31.07,15.21H22.9V6.7H45.58V29.38h-8.5ZM71.1,0V20.88h-17v17h-17v17h-17V77.57h8.51V63.4h17v-17h17v-17h17V0Z"
        />
      </svg>
    )
  }
}

export class IconEval extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 69.9 66.48"
        fill={this.props.fill}
      >
        <path
          className="cls-1"
          d="M35,0,0,25.39,13.35,66.48h43.2L69.9,25.39ZM19.53,58,10,28.64,35,10.51,59.9,28.64,50.37,58Z"
        />
      </svg>
    )
  }
}

export class IconAlumni extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70.03 74.87"
        fill={this.props.fill}
      >
        <path
          className="cls-1"
          d="M66.89,16,70,14.67,35,0,0,14.67,17.38,22a19.2,19.2,0,0,0,3.93,21L5.39,74.87H64.64L48.74,43a19.21,19.21,0,0,0,3.91-21l10-4.18V25.3L59.83,29V43.26h9.88V29l-2.82-3.73Zm-16,50.37H19.14L32.06,40.45,29,38.36a10.68,10.68,0,0,1-4.67-8.82,10.45,10.45,0,0,1,.91-4.3L35,29.34l9.79-4.1a10.69,10.69,0,0,1-3.76,13.12L38,40.46Z"
        />
      </svg>
    )
  }
}

export class IconExternal extends React.Component {
  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 96 96"
        xmlns="http://www.w3.org/2000/svg"
        fill={this.props.fill}
      >
        <path d="M85.3749867,85.375 L10.625,85.375 L10.625,10.75 L48,10.75 L48,0 L0,0 L0,96 L96,96 L96,48 L85.3749867,48 L85.3749867,85.375 Z M57.9725923,0 L57.9725923,10.6965174 L77.5585948,10.6965174 L45,42.5373134 L52.6309207,50 L85.1895155,18.159204 L85.1895155,37.1890415 L96,37.1890415 L96,0 L57.9725923,0 Z"></path>
      </svg>
    )
  }
}
